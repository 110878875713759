<template>
  <div class="markdown-body page-form">
    <Breadcrumb
      :key="$route.path"
      :title="pageTitle"
      subPath="Forms"
      subPathURL="/forms/"
    ></Breadcrumb>
    <v-container :fluid="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <v-row>
        <v-col>
          <v-card class="py-5 px-5 mt-5">
            <v-container>
              <v-row>
                <v-col cols="12" class="text-center">
                  <h1 class="mb-6">ICJIA Conference Room Request</h1>
                </v-col>
              </v-row>
            </v-container>
            <form style="margin-top: 0px">
              <v-container style="background: #fafafa">
                <v-card
                  class="px-10 pt-2 pb-10"
                  d-flex
                  elevation="2"
                  color="grey lighten-4"
                >
                  <v-row>
                    <v-col cols="12">
                      <h2>Contact Information:</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="name"
                        class="heavy"
                        :error-messages="nameErrors"
                        label="Name"
                        required
                        @input="$v.name.$touch()"
                        @blur="$v.name.$touch()"
                        @click="clearAxiosError"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="email"
                        class="heavy"
                        :error-messages="emailErrors"
                        label="E-mail"
                        required
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                        @click="clearAxiosError"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :items="units"
                        label="Select Unit"
                        dense
                        v-if="units"
                        v-model="unit"
                        class="heavy"
                        aria-label="Select Unit"
                        :error-messages="unitErrors"
                        @input="$v.unit.$touch()"
                        @change="$v.unit.$touch()"
                        @blur="$v.unit.$touch()"
                      ></v-select
                    ></v-col>
                  </v-row>
                </v-card>
                <v-row class="mt-5">
                  <v-col cols="12" d-flex>
                    <v-card
                      d-flex
                      elevation="2"
                      class="px-10 py-3"
                      color="grey lighten-4"
                    >
                      <v-col cols="12">
                        <h2 class="mb-10">
                          Conference Room information and occupancy limits:
                        </h2>
                        <table class="">
                          <thead>
                            <tr>
                              <th>Meeting Room</th>
                              <th>Occupancy</th>
                              <th>
                                Half Day Normal Business Hours (up to 4 hours)
                              </th>
                              <th>
                                Full Day Normal Business Hours (up to 8 hours)
                              </th>
                              <th>
                                Half Day Non-Business Hours (up to 4 hours)
                              </th>
                              <th>
                                Full Day Non Business Hours (up to 8 hours)
                              </th>
                              <th>Add Pre-Function Room Use</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>6th Floor: Executive Conference Room</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                            </tr>
                            <tr>
                              <td>6th Floor: Small Conference Room</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                            </tr>
                            <tr></tr>
                            <tr>
                              <td style="width: 200px">
                                3rd Floor: Auditorium
                              </td>
                              <td>254</td>
                              <td>$250</td>
                              <td>$500</td>
                              <td>$250 plus After Hours HVAC fee</td>
                              <td>$500 plus After-Hours HVAC Fee</td>
                              <td>$50</td>
                            </tr>
                            <tr>
                              <td>3rd Floor: Room 318</td>
                              <td>48</td>
                              <td>$100</td>
                              <td>$200</td>
                              <td>$100 plus After Hours HVAC fee</td>
                              <td>$200 plus After-Hours HVAC fee</td>
                              <td>$50</td>
                            </tr>
                            <tr>
                              <td>3rd Floor: Room 319</td>
                              <td>98</td>
                              <td>$125</td>
                              <td>$225</td>
                              <td>$125 plus After Hours HVAC fee</td>
                              <td>$225 plus After-Hours HVAC fee</td>
                              <td>$50</td>
                            </tr>
                            <tr>
                              <td>3rd Floor: 318/319</td>
                              <td>146</td>
                              <td>$150</td>
                              <td>$300</td>
                              <td>$150 plus After Hours HVAC fee</td>
                              <td>
                                318/319 146 $150 $300 $150 plus After-Hours HVAC
                                fee $300 plus After-Hours HVAC fee $50
                              </td>
                              <td>$50</td>
                            </tr>
                            <tr>
                              <td>Tenant Lounge</td>
                              <td>147</td>
                              <td>N/A</td>
                              <td>N/A</td>
                              <td>$200 plus After-Hours HVAC fee</td>
                              <td>$400 plus After-Hours HVAC fee</td>
                              <td>$50</td>
                            </tr>
                            <tr>
                              <td>Off-Site State Space Location (Cost TBD)</td>
                              <td>N/A</td>
                              <td>N/A</td>
                              <td>N/A</td>
                              <td>---</td>
                              <td>---</td>
                              <td>---</td>
                            </tr>
                          </tbody>
                        </table>
                        <ul>
                          <li>
                            <p>
                              Reservations outside of normal operating hours are
                              acceptable with prior approval by Building
                              Manager. After-hours amenity reservation will
                              warrant additional charges to run HVAC.
                            </p>
                          </li>
                          <li>
                            <p>
                              Tenant Lounge reservations will begin after 5:30
                              PM only.
                            </p>
                          </li>
                          <li>
                            <p>
                              You may add Pre-Function Room Use for $50, during
                              normal and non-business hours on a first come,
                              first served basis.
                            </p>
                          </li>
                          <li>
                            <p>
                              Strict adherence to your scheduled times is
                              required. If additional time is needed for setup
                              or break down, that must be detailed on the
                              reservation form and needs to be approved by
                              Management.
                            </p>
                          </li>
                          <li>
                            <p>
                              All events require 30 minutes between bookings
                              unless previously discussed with the Tenant
                              Services Representative at time of reservation.
                            </p>
                          </li>
                          <li>
                            <p>
                              If you require use of extension cords this must be
                              requested at the time of booking.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong
                                >No charge for 6th floor conference
                                rooms.</strong
                              >
                            </p>
                          </li>
                        </ul>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="rooms"
                          label="Select conference room"
                          dense
                          v-if="rooms"
                          v-model="room"
                          class="heavy"
                          aria-label="Conference Room"
                          :error-messages="roomErrors"
                          @input="$v.room.$touch()"
                          @change="$v.room.$touch()"
                          @blur="$v.room.$touch()"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="schedules"
                          label="Select Room schedule"
                          dense
                          v-if="schedules"
                          v-model="schedule"
                          class="heavy"
                          aria-label="Room schedule"
                          :error-messages="scheduleErrors"
                          @input="$v.schedule.$touch()"
                          @change="$v.schedule.$touch()"
                          @blur="$v.schedule.$touch()"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="setups"
                          label="Select Room setup"
                          dense
                          v-if="setups"
                          v-model="setup"
                          class="heavy"
                          aria-label="Room setup"
                          :error-messages="setupErrors"
                          @input="$v.setup.$touch()"
                          @change="$v.setup.$touch()"
                          @blur="$v.setup.$touch()"
                        ></v-select>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" d-flex>
                    <v-card
                      d-flex
                      elevation="2"
                      class="px-10 py-1"
                      color="grey lighten-4"
                    >
                      <h2 class="mb-2">Event Information:</h2>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="eventType"
                          class="heavy"
                          label="Type of Event (Business purposes only)"
                          required
                          prepend-icon="person"
                          :error-messages="eventTypeErrors"
                          @input="$v.eventType.$touch()"
                          @blur="$v.eventType.$touch()"
                          @click="clearAxiosError"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <Datepicker
                          refName="start_date"
                          @start_date="getFieldData"
                          label="Meeting date"
                          :key="render"
                        ></Datepicker>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-select
                          :items="time_intervals"
                          class="heavy"
                          label="Setup time will begin at:"
                          dense
                          v-if="time_intervals"
                          v-model="start_time"
                          aria-label="Select setup start"
                          prepend-icon="mdi-clock"
                          :error-messages="startTimeErrors"
                          @input="$v.start_time.$touch()"
                          @change="$v.start_time.$touch()"
                          @blur="$v.start_time.$touch()"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="12">
                        <v-select
                          :items="time_intervals"
                          class="heavy"
                          label="Room will be vacated at:"
                          dense
                          v-if="time_intervals"
                          v-model="end_time"
                          aria-label="Meeting end time"
                          prepend-icon="mdi-clock"
                          :error-messages="endTimeErrors"
                          @input="$v.end_time.$touch()"
                          @change="$v.end_time.$touch()"
                          @blur="$v.end_time.$touch()"
                        ></v-select>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
                <v-card
                  elevation="2"
                  class="px-10 py-1 mt-5"
                  color="grey lighten-4"
                >
                  <v-row class="mt-3">
                    <v-col cols="12"><h2 class="">Additions:</h2></v-col>
                    <v-col
                      v-for="(accessory, index) in accessories"
                      :key="index"
                      cols="12"
                      sm="6"
                      md="3"
                      style="margin-top: -20px"
                    >
                      <v-checkbox
                        v-model="accessories_requested"
                        :label="accessory"
                        :value="accessory"
                        class="heavy"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>

                <v-row class="mt-3">
                  <v-col cols="12">
                    <v-card
                      elevation="2"
                      class="px-10 py-1"
                      color="grey lighten-4"
                    >
                      <h2 class="mb-10">Catering Information:</h2>
                      <v-col cols="12">
                        <v-select
                          :items="cateredChoice"
                          class="heavy"
                          label="Will this event be catered"
                          dense
                          v-if="cateredChoice"
                          v-model="cateredDefault"
                          aria-label="Will this even be catered?"
                          prepend-icon="mdi-clock"
                          @change="getVendor()"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="alcoholChoice"
                          class="heavy"
                          label="Will alcohol be served?"
                          dense
                          v-if="alcoholChoice"
                          v-model="alcoholDefault"
                          aria-label="Will alcohol be served?"
                          prepend-icon="mdi-clock"
                          @change="getVendor()"
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-if="displayVendor"
                          prepend-icon="people"
                          ref="vendorText"
                          v-model="vendor"
                          class="heavy"
                          label="Vendor (if catering or alcohol selected)"
                          hint="Certificate of Insurance on file with 333 S. Wabash is required."
                        ></v-text-field>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="">
                  <v-col cols="12">
                    <h2 class="mt-10">Additional Comments:</h2>
                    <v-textarea
                      v-model="comment"
                      auto-grow
                      filled
                      label="Additional comments, requests, or requirements:"
                      rows="10"
                      class="mt-3"
                      @click="clearAxiosError"
                      ref="comment"
                      aria-label="Additional requests"
                    ></v-textarea>
                    <!-- <div v-if="formData">
                      {{ formData }}
                    </div> -->
                  </v-col>
                </v-row>
              </v-container>

              <div v-if="showSubmit" class="text-center">
                <v-btn @click="submit" dark color="blue darken-4">submit</v-btn>
                <v-btn @click="clear" class="ml-2">clear</v-btn>&nbsp;
                <span v-if="showLoader">
                  <v-progress-circular
                    indeterminate
                    aria-label="Progress bar: Loading"
                    color="primary"
                  ></v-progress-circular>
                </span>
              </div>

              <div v-if="!showSubmit" class="text-center" style="color: green">
                {{ successMessage }}
              </div>
              <div
                v-if="showAxiosError"
                style="color: red; font-size: 14px"
                class="mt-3 text-center"
              >
                <b style="font-size: 20px"
                  >CONFERENCE ROOM RESERVATION NOT SENT</b
                >
                <br />
                <br />
                {{ axiosError }}
              </div>
              <div
                v-if="$v.$anyError"
                style="color: red; font-weight: bold"
                class="mt-5 text-center"
              >
                The form has errors.
                <router-link to="/forms/support/"
                  >Please contact technical support</router-link
                >.
              </div>
              .
            </form>
          </v-card>
          <div class="mt-3 text-center" style="font-size: 12px">
            Questions or problems with this form?
            <router-link to="/forms/support/"
              >Please contact technical support</router-link
            >.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-undef */

import { validationMixin } from "vuelidate";
// eslint-disable-next-line no-unused-vars
import { required, email, integer } from "vuelidate/lib/validators";
import DOMPurify from "dompurify";
import { generateHours } from "@/services/Utils";
// eslint-disable-next-line no-unused-vars
import { dbInsert } from "@/services/Forms";
// import NProgress from "nprogress";
// eslint-disable-next-line no-unused-vars
import axios from "axios";

export default {
  mixins: [validationMixin],

  head() {
    return {};
  },
  mounted() {
    this.units = this.$myApp.units.map((unit) => {
      let obj = {};
      obj.text = `${unit.title}`;
      obj.value = unit.title;
      return obj;
    });
    this.time_intervals = generateHours();
  },

  validations: {
    name: { required },
    email: { required, email },
    unit: { required },
    // vendor: { required },

    room: { required },
    schedule: { required },
    start_time: { required },
    end_time: { required },
    setup: { required },
    // cleanup: { required },
    // number_attending: { required, integer },
    eventType: { required },
  },
  data() {
    return {
      name: "",
      email: this.$store.state.auth.userMeta.email || null,
      unit: "",
      // number_attending: null,
      // cleanup: null,
      title: null,
      room: null,
      schedule: null,
      comment: "",
      vendor: "",
      end_time: null,
      start_time: null,
      form: null,
      showSubmit: true,
      showAxiosError: false,
      axiosError: "",
      showLoader: false,
      id: "",
      cateredDefault: "No",
      alcoholDefault: "No",
      successMessage: "",
      isIE: null,
      units: null,
      displayVendor: false,
      render: false,
      time_intervals: null,
      eventType: null,
      setup: null,
      accessories: [
        " Teleconference link",
        " Projector",
        " Flipchart / markers",
      ],
      accessories_requested: [],
      rooms: [
        "6th floor: Executive Conference Room (No charge.)",
        "6th floor: Small Conference Room (No charge. Includes whiteboard, phone, and internet access)",
        "3rd Floor: Auditorium (See room rates above)",
        "3rd Floor: Room 318 (See room rates above)",
        "3rd Floor: Room 319 (See room rates above)",
        "3rd Floor: Rooms 318 + 319 (See room rates above)",
        "Tenant Lounge (See room rates above)",
        "Off-Site State Space Location (Cost TBD)",
      ],
      schedules: [
        "Half Day AM (8:30am-12:00pm)",
        "Half Day PM (1:00pm-5:00pm)",
        "Full Day (8:30am-5:00pm)",
        "Other (specify requests or requirements in 'Additional Comments' below)",
      ],
      cateredChoice: ["Yes", "No"],
      alcoholChoice: ["Yes", "No"],
      setups: [
        "No setup necessary",
        "Classroom style",
        "U Shaped",
        "Boardroom style",
      ],
    };
  },
  computed: {
    pageTitle() {
      return "Conference Room Request";
    },

    permalink() {
      return null;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");

      return errors;
    },
    unitErrors() {
      const errors = [];
      if (!this.$v.unit.$dirty) return errors;
      !this.$v.unit.required && errors.push("Unit is required");

      return errors;
    },

    roomErrors() {
      const errors = [];
      if (!this.$v.room.$dirty) return errors;
      !this.$v.room.required && errors.push("Conference room is required");

      return errors;
    },
    setupErrors() {
      const errors = [];
      if (!this.$v.setup.$dirty) return errors;
      !this.$v.setup.required && errors.push("Setup information is required");

      return errors;
    },
    scheduleErrors() {
      const errors = [];
      if (!this.$v.schedule.$dirty) return errors;
      !this.$v.schedule.required && errors.push("Room schedule is required");

      return errors;
    },
    // vendorErrors() {
    //   const errors = [];
    //   if (!displayVendor) return [];
    //   if (!this.$v.vendor.$dirty) return errors;
    //   !this.$v.vendor.required &&
    //     errors.push("Vendor is required if catering is selected");

    //   return errors;
    // },
    startTimeErrors() {
      const errors = [];
      if (!this.$v.start_time.$dirty) return errors;
      !this.$v.start_time.required && errors.push("Start time is required");

      return errors;
    },
    endTimeErrors() {
      const errors = [];
      if (!this.$v.end_time.$dirty) return errors;
      !this.$v.end_time.required && errors.push("End time is required");

      return errors;
    },

    eventTypeErrors() {
      const errors = [];
      if (!this.$v.eventType.$dirty) return errors;
      !this.$v.eventType.required && errors.push("Type of event is required.");

      return errors;
    },

    // eslint-disable-next-line no-unused-vars
    isSuccess(v) {
      console.log("isSuccess", !this.$v.$invalid, this.$v.$dirty);
      return !this.$v.$invalid && this.$v.$dirty;
    },
  },
  methods: {
    getVendor() {
      if (this.cateredDefault === "Yes" || this.alcoholDefault === "Yes") {
        this.displayVendor = true;
      } else {
        this.displayVendor = false;
      }
      //   this.displayVendor = !this.displayVendor;
      if (this.displayVendor) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.vendorText.$refs.input.focus();
          });
        });
      }

      return;
    },

    getFieldData(v) {
      //console.log("value: ", v);
      this[v.refName] = v.value;
      //console.log(this[v.refName]);
    },
    clearAxiosError() {
      return (this.showAxiosError = false);
    },
    async reload() {
      this.render = false;
      await this.$nextTick();
      this.render = true;
    },

    async submit() {
      this.$v.$touch();

      if (this.isSuccess) {
        window.NProgress.start();
        this.showLoader = true;
        // sanitize comment, then strip html
        const cleanComment = DOMPurify.sanitize(this.comment).replace(
          /(<([^>]+)>)/gi,
          ""
        );

        this.comment = cleanComment;

        let text = {
          type: this.pageTitle,
          name: this.name,
          email: this.email,
          unit: this.unit,
          room: this.room,
          eventType: this.eventType,
          setup: this.setup,
          vendor: this.vendor,
          alcohol: this.alcoholDefault,
          catered: this.cateredDefault,
          accessories_requested: this.accessories_requested,
          comment: this.comment,
        };

        let dates = {
          start_date: this.start_date,
          start_time: this.start_time,
          end_time: this.end_time,
        };

        this.form = { ...text, ...dates };

        console.dir(JSON.stringify(this.form));

        // eslint-disable-next-line no-unused-vars
        let options = {
          method: "POST",
          data: this.form,
          url: "https://mail.icjia.cloud/intranet/conference",
        };

        let dbResponse = await dbInsert(this.$store.state.auth.jwt, this.form);
        console.log("dbinsert: ", dbResponse);

        this.success(dbResponse);
        console.log(options);

        try {
          let res = await axios(options);
          this.success(res);
        } catch (err) {
          this.failed(err);
        }
      }
    },
    failed(res) {
      console.log("email: ", res);
      this.showAxiosError = true;
      this.axiosError = res;
      this.showLoader = false;
      window.NProgress.done();
      this.reload();
    },
    success(res) {
      //console.log("email: ", res);
      this.showSubmit = false;
      this.showAxiosError = false;
      this.showError = "";
      this.successMessage = res.data.msg;
      this.showLoader = false;
      window.NProgress.done();
      this.reload();
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = this.$store.state.auth.userMeta.email || null;
      this.comment = "";
      this.unit = "";
      this.room = "";
      this.end_time = null;
      this.start_time = null;
      this.showAxiosError = false;
      this.axiosError = "";
      this.showLoader = false;
      this.eventType = null;
      this.form = null;
      this.vendor = null;
      this.accessories_requested = [];
      this.displayVendor = false;
      this.cateredDefault = "No";
      this.alcoholDefault = "No";
      this.setup = null;
      this.reload();
    },
  },
};
</script>

<style></style>
