<template>
  <div class="text-center page-form">
    <h1>Vehicle Request form here</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
